<template>
  <div class="top-bar w-100 d-flex align-items-center">
    <ion-button class="back" @click="back"><i class="ti-arrow-left mr-1" />Go back</ion-button>
  </div>
</template>

<script lang="ts" setup>
const router = useRouter();

const back = () => {
  router.back();
};
</script>

<style scoped lang="sass">
.top-bar
  height: 50px
  font-size: 14px
  i
    font-size: 12px
  .back
    --background: rgba(33, 65, 99, 1)
    --border-radius: 20px
</style>
